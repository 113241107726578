@use "../../global" as g;

.form {
  padding: 10rem 0 10rem 41.6rem;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    width: 70rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  &__item_ttl {
    font-size: g.$fs18;
    font-weight: bold;
  }

  &__item_input {
    padding: 1rem;
    width: 100%;
    height: 4rem;
    border: 1px solid rgb(167, 167, 167);
    border-radius: 5px;
  }

  &__submit {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    font-size: g.$fs18;
    font-weight: bold;
    width: 30rem;
    height: 6rem;
    border-radius: 5px;
    background-color: rgb(215, 250, 217);
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
