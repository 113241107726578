/* base
----------------------------------------------------------------- */
@use "../global" as g;

html {
  font-size: 62.5%;
  height: 100%;
  @include g.mobileS {
    font-size: calc(1vw * 1000 / 360);
  }
}

body {
  font-size: g.$fs16;
}

.wrapper {
  display: flex;
}
