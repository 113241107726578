/* --------------------------------------------------------------------- 
color
-----------------------------------------------------------------------*/
$black: #333333;
$base_black: #404040;
$red: #bc1d21;
$gray: #bfbeb4;

/* --------------------------------------------------------------------- 
font-family
-----------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;700&family=Noto+Sans+JP:wght@400;500;700&family=Noto+Serif+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&display=swap");
$mincho: "Noto Serif JP", serif;
$gothic: "Noto Sans JP", sans-serif;
$cardo: "Cardo", serif;

/* --------------------------------------------------------------------- 
font-size
-----------------------------------------------------------------------*/
$fs10: 1rem;
$fs11: 1.1rem;
$fs12: 1.2rem;
$fs13: 1.3rem;
$fs14: 1.4rem;
$fs15: 1.5rem;
$fs16: 1.6rem;
$fs17: 1.7rem;
$fs18: 1.8rem;
$fs19: 1.9rem;
$fs20: 2rem;
$fs21: 2.1rem;
$fs22: 2.2rem;
$fs23: 2.3rem;
$fs24: 2.4rem;
$fs25: 2.5rem;
$fs26: 2.6rem;
$fs27: 2.7rem;
$fs28: 2.8rem;
$fs29: 2.9rem;
$fs30: 3rem;
$fs31: 3.1rem;
$fs32: 3.2rem;
$fs33: 3.3rem;
$fs34: 3.4rem;
$fs35: 3.5rem;
$fs36: 3.6rem;
$fs37: 3.7rem;
$fs38: 3.8rem;
$fs39: 3.9rem;
$fs40: 4rem;
$fs41: 4.1rem;
$fs42: 4.2rem;
$fs43: 4.3rem;
$fs44: 4.4rem;
$fs45: 4.5rem;
$fs46: 4.6rem;
$fs47: 4.7rem;
$fs48: 4.8rem;
$fs49: 4.9rem;
$fs50: 5rem;
$fs51: 5.1rem;
$fs52: 5.2rem;
$fs53: 5.3rem;
$fs54: 5.4rem;
$fs55: 5.5rem;
$fs56: 5.6rem;
$fs57: 5.7rem;
$fs58: 5.8rem;
$fs59: 5.9rem;
$fs60: 6rem;
$fs61: 6.1rem;
$fs62: 6.2rem;
$fs63: 6.3rem;
$fs64: 6.4rem;
$fs65: 6.5rem;
$fs66: 6.6rem;
$fs67: 6.7rem;
$fs68: 6.8rem;
$fs69: 6.9rem;
$fs70: 7rem;
$fs71: 7.1rem;
$fs72: 7.2rem;
$fs73: 7.3rem;
$fs74: 7.4rem;
$fs75: 7.5rem;
$fs76: 7.6rem;
$fs77: 7.7rem;
$fs78: 7.8rem;
$fs79: 7.9rem;
$fs80: 8rem;
$fs81: 8.1rem;
$fs82: 8.2rem;
$fs83: 8.3rem;
$fs84: 8.4rem;
$fs85: 8.5rem;
$fs86: 8.6rem;
$fs87: 8.7rem;
$fs88: 8.8rem;
$fs89: 8.9rem;
$fs90: 9rem;
$fs91: 9.1rem;
$fs92: 9.2rem;
$fs93: 9.3rem;
$fs94: 9.4rem;
$fs95: 9.5rem;
$fs96: 9.6rem;
$fs97: 9.7rem;
$fs98: 9.8rem;
$fs99: 9.9rem;
$fs100: 10rem;
$fs101: 10.1rem;
$fs101: 10.1rem;
$fs102: 10.2rem;
$fs103: 10.3rem;
$fs104: 10.4rem;
$fs105: 10.5rem;
$fs106: 10.6rem;
$fs107: 10.7rem;
$fs108: 10.8rem;
$fs109: 10.9rem;
$fs110: 11rem;
$fs111: 11.1rem;
$fs112: 11.2rem;
$fs113: 11.3rem;
$fs114: 11.4rem;
$fs115: 11.5rem;
$fs116: 11.6rem;
$fs117: 11.7rem;
$fs118: 11.8rem;
$fs119: 11.9rem;
$fs120: 12rem;
$fs121: 12.1rem;
$fs122: 12.2rem;
$fs123: 12.3rem;
$fs124: 12.4rem;
$fs125: 12.5rem;
$fs126: 12.6rem;
$fs127: 12.7rem;
$fs128: 12.8rem;
$fs129: 12.9rem;
$fs130: 13rem;
$fs131: 13.1rem;
$fs132: 13.2rem;
$fs133: 13.3rem;
$fs134: 13.4rem;
$fs135: 13.5rem;
$fs136: 13.6rem;
$fs137: 13.7rem;
$fs138: 13.8rem;
$fs139: 13.9rem;
$fs140: 14rem;
$fs141: 14.1rem;
$fs142: 14.2rem;
$fs143: 14.3rem;
$fs144: 14.4rem;
$fs145: 14.5rem;
$fs146: 14.6rem;
$fs147: 14.7rem;
$fs148: 14.8rem;
$fs149: 14.9rem;
$fs150: 15rem;
