/* mediaquery
----------------------------------------------------------------- */
$wideDisplayHero: 2001px;
@mixin wideDisplayHero {
  @media (min-width: ($wideDisplayHero)) {
    @content;
  }
}

$desktopFull: 1920px;
@mixin desktopFull {
  @media (max-width: ($desktopFull)) {
    @content;
  }
}

$desktopL: 1800px;
@mixin desktopL {
  @media (max-width: ($desktopL)) {
    @content;
  }
}

$desktopM: 1560px;
@mixin desktopM {
  @media (max-width: ($desktopM)) {
    @content;
  }
}
$desktopS: 1448px;
@mixin desktopS {
  @media (max-width: ($desktopS)) {
    @content;
  }
}
$note: 1232px;
@mixin note {
  @media (max-width: ($note)) {
    @content;
  }
}
$tabletLL: 1080px;
@mixin tabletLL {
  @media (max-width: ($tabletLL)) {
    @content;
  }
}
@mixin hover {
  @media (min-width: (1081px)) {
    @content;
  }
}
$tabletL: 1024px;
@mixin tabletL {
  @media (max-width: ($tabletL)) {
    @content;
  }
}
$tabletM: 960px;
@mixin tabletM {
  @media (max-width: ($tabletM)) {
    @content;
  }
}
$tabletS: 768px;
@mixin tabletS {
  @media (max-width: ($tabletS)) {
    @content;
  }
}
$mobileLL: 600px;
@mixin mobileLL {
  @media (max-width: ($mobileLL)) {
    @content;
  }
}
$mobileL: 425px;
@mixin mobileL {
  @media (max-width: ($mobileL)) {
    @content;
  }
}
$mobileML: 386px;
@mixin mobileML {
  @media (max-width: ($mobileML)) {
    @content;
  }
}
$mobileM: 375px;
@mixin mobileM {
  @media (max-width: ($mobileM)) {
    @content;
  }
}
$mobileS: 359px;
@mixin mobileS {
  @media (max-width: ($mobileS)) {
    @content;
  }
}
