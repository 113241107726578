@use "../../global" as g;

.aside {
  position: fixed;
  top: 0;
  left: 0;
  padding: 10rem 0 0;
  background-color: rgb(255, 247, 236);
  height: 100vh;

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.4rem;
    width: 40rem;
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__item {
    padding: 1.6rem 2.4rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid white;
    height: fit-content;
    background-color: blanchedalmond;
    cursor: pointer;
    transition: 0.3s;

    &:first-child {
      border-top: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__item_txt {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  &__item_remove {
    text-align: center;
    padding: 0.6rem 1rem;
    flex-shrink: 0;
    background-color: red;
    color: white;
  }

  &__new-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 2.4rem;
    width: 100%;
    background-color: rgb(215, 250, 217);
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}
